const env_constants = {
    API_BASE_URL: 'https://admin.e-store.gtechweb.au/api',  //'https://dev-yazi.laquarzo.com/api',
    API_BASE_PUBLIC_URL: 'https://admin.e-store.gtechweb.au/', //'https://dev-yazi.laquarzo.com/',
    POPULAR_COUNT: 1,
    STORE_NAME: 'Yazi Store',
    STORE_META_DESCRIPTION: 'Yazi Store, an online plateform to buy and tools and products.',
    STORE_META_KEYWORDS: 'Yazi Store, online plateform, tools and products',
    STORE_META_AUTHOR: 'Yazi Store',
};

export default env_constants;